import React from 'react'
import { Link } from 'gatsby'

const Item = props => {
  const { isEven, title, description, image, createdAt, postTags, path } = props
  return (
    <li className={["post__item", "d-md-flex", "justify-content-between", isEven ? "flex-row" : "flex-row-reverse"].join(" ")} >
      <Link to={path} className="post__thumb">
        <picture>
          <source src={image.fluid.srcWebp} type="image/webp" />
          <img
            loading="lazy"
            src={image.fluid.src}
            title={image.title}
            alt={image.description}
            style={{ maxHeight: "310px", objectFit: "cover" }}
          />
        </picture>
      </Link>
      <div className="post__wrap d-flex flex-column justify-content-between">
        <div className="post__group">
          <div className="tag-list">
            {postTags}
          </div>
          <h2 className="post__title"><Link to={path} className="post__text">{title}</Link></h2>
          <p className="post__description">
            {description}
          </p>
        </div>
        <div className="post__group d-flex justify-content-between align-items-end">
          <div className="post__date">{createdAt}</div>
          <div className="c-btn">
            <Link to={path} className="btn-outline">続きを読む</Link>
          </div>
        </div>
      </div>
    </li>
  )
}

export default Item
