import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import classNames from 'classnames'
import SelectedList from './selectedList'
import logo from '../images/logo.svg'
import { myLogueUrl } from '../helpers/urlHelper'

class ListLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fadeIn: true,
      orangeBalloonIn: false,
      blueBalloonIn: false,
      contentsIn: false
    }
  }
  componentDidMount() {
    setTimeout(() => { this.setState({ balloonOrangeIn: true }) }, 300);
    setTimeout(() => { this.setState({ balloonBlueIn: true }) }, 600);
    setTimeout(() => { this.setState({ contentsIn: true }) }, 900);
  }
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            allContentfulPost(sort: {fields: createdAt, order: DESC}) {
              edges {
                node {
                  slug
                  title
                  category {
                    ... on ContentfulCategory1 {
                      name
                      slug
                    }
                    ... on ContentfulCategory2 {
                      name
                      slug
                      category1 {
                        slug
                        name
                      }
                    }
                    ... on ContentfulCategory3 {
                      name
                      slug
                      category2 {
                        slug
                        name
                        category1 {
                          slug
                          name
                        }
                      }
                    }
                  }
                  postTags {
                    tag
                    slug
                  }
                  createdAt
                  image {
                    fluid (maxWidth: 300) {
                      src
                      srcWebp
                    }
                  }
                  recommended
                  popular
                }
              }
            }
            allPageViews {
              edges {
                node {
                  path
                }
              }
            }
            balloonOrange:file(relativePath: {eq: "balloon-orange.png"}) {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
            balloonBlue:file(relativePath: {eq: "balloon-blue.png"}) {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
        `}
        render={data => {
          const { title, children } = this.props
          const posts = data.allContentfulPost.edges
          const recommendedPosts = posts.filter(post => post.node.recommended)
          const popularPosts = []
          data.allPageViews.edges.forEach(edge => {
            const post = posts.find(e => myLogueUrl(e.node).slice(myLogueUrl(e.node).lastIndexOf('/')) === edge.node.path.slice(edge.node.path.lastIndexOf('/')))
            if (post) {
              popularPosts.push(post)
            }
          })
          const coverWrapClass = classNames('cover__wrap', 'js_fade', {
            'js_fade_in': this.state.fadeIn
          })
          const orangeBalloonClass = classNames('cover__balloon', 'orange', 'js_slide', {
            'js_slide_in': this.state.balloonOrangeIn
          })
          const blueBalloonClass = classNames('cover__balloon', 'blue', 'js_slide', {
            'js_slide_in': this.state.balloonBlueIn
          })
          const contentClass = classNames('js_fade', {
            'js_fade_in': this.state.contentsIn
          })
          return (
            <main className="main">
              <div className="cover">
                <div className="container">
                  <div className={coverWrapClass}>
                    <div className="cover__logo">
                      <img alt="" src={logo} />
                    </div>
                    <p className="cover__description">My-logue(マイローグ)は、AIやチャットボットを使って<br
                      className="d-none d-md-block" />業務効率化を実現した事例の紹介や、<br
                        className="d-none d-md-block" />上手くいくチャットボットの育て方・活用の仕方などをご紹介していきます。
                      </p>
                  </div>
                  <div className={orangeBalloonClass}><img alt="" src={data.balloonOrange.childImageSharp.fluid.src} /></div>
                  <div className={blueBalloonClass}><img alt="" src={data.balloonBlue.childImageSharp.fluid.src} /></div>
                </div>
              </div>
              <div className={contentClass} id="contents">
                <section className="section">
                  <div className="container">
                    <div className="heading-top">
                      <h3>{title}</h3>
                      <p>記事一覧</p>
                    </div>
                    <div className="post">
                      <ul className="post__list">
                        {children}
                      </ul>
                    </div>
                  </div>
                </section>
                <section id="popular" className="section bg-light-gray">
                  <div className="container">
                    <div className="heading-top">
                      <h3>POPULAR</h3>
                      <p>人気記事</p>
                    </div>
                    <SelectedList posts={popularPosts.slice(0, 3)} />
                  </div>
                </section>
                <section className="section">
                  <div className="container">
                    <div className="heading-top">
                      <h3>RECOMMEND</h3>
                      <p>おすすめ記事</p>
                    </div>
                    <SelectedList posts={recommendedPosts.slice(0, 3)} />
                  </div>
                </section>
              </div>
            </main>
          )
        }
        }
      />
    )
  }
}

export default ListLayout
