import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import Tag from './tag'
import { formatDate } from '../helpers/dateHelper'
import { myLogueUrl } from '../helpers/urlHelper'

const SelectedList = props => {
  const contents = []
  for (let i in props.posts) {
    const tags = []
    for (let j in props.posts[i].node.postTags) {
      tags.push(
        <Tag key={j} tag={props.posts[i].node.postTags[j]} />
      )
    }
    const path = myLogueUrl(props.posts[i].node)
    contents.push(
      <li key={i} className="post__item--column d-flex flex-column">
        <Link to={path} className="post__thumb--column aside-img">
          <picture>
            <source src={props.posts[i].node.image.fluid.srcWebp} type="image/webp" />
            <img
              loading="lazy"
              src={props.posts[i].node.image.fluid.src}
              title={props.posts[i].node.image.title}
              alt={props.posts[i].node.image.description}
              style={{ maxHeight: "200px", objectFit: "cover" }}
            />
          </picture>
        </Link>
        <div className="post__wrap--column d-flex flex-column justify-content-between">
          <div className="post__group">
            <div className="tag-list">
              {tags}
            </div>
            <Link className="post__text" to={path} ></Link>
            <h2 className="post__title--small">{props.posts[i].node.title}</h2>
          </div>
          <div className="post__group mb-3 d-flex justify-content-between align-items-end">
            <div className="post__date">{formatDate(new Date(props.posts[i].node.createdAt))}</div>
            <div className="c-btn">
              <Link to={path} className="btn-outline">続きを読む</Link>
            </div>
          </div>
        </div>
      </li>
    )
  }
  return (
    <div className="post">
      <ul className="post__list d-md-flex justify-content-between">
        {contents}
      </ul>
    </div>
  )
}

SelectedList.propTypes = {
  posts: PropTypes.array
}

export default SelectedList
