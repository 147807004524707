import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Tag from '../components/tag'
import Item from '../components/item'
import ListLayout from '../components/listLayout'
import { formatDate } from '../helpers/dateHelper'
import { myLogueUrl } from '../helpers/urlHelper'

const NavLink = props => {
  if (!props.posts) {
    return <Link
      to={props.url}
      className={[props.active ? 'active' : '', props.active ? 'disabled' : ''].join(' ')}>
      <span>{props.text}</span>
    </Link>
  } else {
    return <span></span>
  }
}

const postList = ({ pathContext: { pathPrefix }, pageContext: { title, group, index, first, last, pageCount } }) => {
  const previousUrl = `${pathPrefix}/${index - 1 === 1 ? '/' : (index - 1).toString()}`
  const nextUrl = `${pathPrefix}/${(index + 1).toString()}`
  const contents = []
  const pageNums = []
  for (let i in group) {
    const tags = []
    for (let j in group[i].node.postTags) {
      tags.push(
        <Tag key={j} tag={group[i].node.postTags[j]} />
      )
    }
    contents.push(
      <Item
        key={i}
        isEven={i % 2 === 0}
        slug={group[i].node.slug}
        title={group[i].node.title}
        description={group[i].node.description ? group[i].node.description.description : null}
        image={group[i].node.image}
        createdAt={formatDate(new Date(group[i].node.createdAt))}
        postTags={tags}
        path={myLogueUrl(group[i].node)}
      />
    )
  }
  for (let i = 0; i < pageCount; i++) {
    const page = i + 1
    pageNums.push(
      <li key={i}>
        <NavLink
          url={`${pathPrefix}/${page === 1 ? '' : page}`}
          text={page}
          active={index === page}
        />
      </li>
    )
  }
  return (
    <Layout>
      <SEO title={pathPrefix !== '/my-logue' ? `${title}記事一覧` : 'My-logue(マイローグ)'} url={pathPrefix !== '/my-logue' ? title : ''} />
      <ListLayout title={title}>
        {contents}
        <div className="pager">
          <ul className="pages">
            <li className={['pre', first ? 'disabled' : ''].join(' ')}>
              <NavLink posts={first} url={previousUrl} text="前へ" />
            </li>
            {pageNums}
            <li className={['next', last ? 'disabled' : ''].join(' ')}>
              <NavLink posts={last} url={nextUrl} text="次へ" />
            </li>
          </ul>
        </div>
      </ListLayout>
    </Layout>
  )
}
export default postList
